<script>
  

</script>

<style lang="scss">
.social_buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    // align-items: ;
    width: 100%;

    color: inherit;
    font-size: 1.25rem;
    padding: .5rem 0 0;
    margin: 0;
}

// Adjust later
.social {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
   
}
a {
     margin: 2.6rem;
}

</style>

<div class="social_buttons">
<!-- <img class="w-48 h-48 rounded-full" src="/assets/imgs/azeez.jpg" alt="my-avatar" /> -->
<div class="social">
    <a href="https://github.com/Oluwapelumi101/" target="_blank" rel="noopener noreferrer">
        <i class="fab fa-github"></i> 
    </a>

    <a href="#" target="_blank" rel="noopener noreferrer">
        <i class="fab fa-twitter"></i>  
    </a>

    <a href="https://linkedin.com/in/zeez-tech" target="_blank" rel="noopener noreferrer">
        <i class="fab fa-linkedin"></i> 
    </a>
</div>
</div>
